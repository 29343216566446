@keyframes moveTractorHorizontal1 {
    0% {
      transform: translateX(-450%);
      visibility: hidden;
    }
    5%, 95% {
      visibility: visible;
    }
    100% {
      transform: translateX(650%);
      visibility: hidden;
    }
  }
  
  @keyframes moveTractorHorizontal2 {
    0% {
      transform: translateX(-450%);
      visibility: hidden;
    }
    5%, 95% {
      visibility: visible;
    }
    100%  {
      transform: translateX(650%);
      visibility: hidden;
    }
  }
  
  @keyframes moveTractorVertical1 {
    0% {
      transform: translateY(-100%);
      visibility: hidden;
    }
    5%, 95% {
      visibility: visible;
    }
    100% {
      transform: translateY(1500%);
      visibility: hidden;
    }
  }
  
  @keyframes moveTractorVertical2 {
    0% {
      transform: translateY(-100%);
      visibility: hidden;
    }
    5%, 95% {
      visibility: visible;
    }
    100% {
      transform: translateY(1500%);
      visibility: hidden;
    }
  }

  .tractor-animation {
    width: 70px;
    height: auto;
    position: absolute;
    color: rgb(3, 114, 52);
  }
  
  .moveTractorHorizontal1, .moveTractorHorizontal2, .moveTractorVertical1, .moveTractorVertical2 {
    animation: 2s linear; /* Adjust the timing as needed */
    animation-timing-function: linear;
    animation-fill-mode: forwards; /* Ensures the element stays in the final state of the animation */
    animation-iteration-count: 1; /* Run only once */
  }
  
  .moveTractorHorizontal1 { animation-name: moveTractorHorizontal1; }
  .moveTractorHorizontal2 { animation-name: moveTractorHorizontal2; }
  .moveTractorVertical1 { animation-name: moveTractorVertical1; }
  .moveTractorVertical2 { animation-name: moveTractorVertical2; }